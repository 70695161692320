import { Button as MuiButton } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

import { rem } from 'src/common/utils/css'

import theme, { SECONDARY_COLOR, SERVICE_COLOR, SHADOW } from 'src/styles/theme'

const useStyles = makeStyles()((defaultTheme, props) => ({
  button: {
    width: rem(48),
    height: rem(48),
    minWidth: 'unset',
    padding: 'unset',
    borderRadius: '50%',
    color: 'unset',
    backgroundColor: theme.palette.primary.dark,
    '&: hover': {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: SHADOW.LEVEL_THREE,
      [theme.breakpoints.up('md')]: {
        backgroundColor: SECONDARY_COLOR.DARK[80],
      },
    },
    '@media (hover: none)': {
      '&: hover': {
        color: 'unset',
        backgroundColor: theme.palette.primary.dark,
        boxShadow: 'none',
      },
    },
    '&: disabled': {
      backgroundColor: SERVICE_COLOR.DISABLED.SECONDARY,
      boxShadow: 'none',
    },
    '&: active': {
      background: theme.palette.secondary.dark,
      boxShadow: 'none',
    },
  },
}))

function FloatingActionButton({ children, className, disabled, ...props }) {
  if (!children || typeof children === 'string') return null

  const { classes } = useStyles({ disabled })

  const iconColor = React.useMemo(() => {
    if (disabled) return theme.palette.disabled.dark
    return theme.palette.presidio.color.NEAR_WHITE
  }, [disabled])

  const [colorIcon, setColorIcon] = React.useState(iconColor)

  React.useEffect(() => {
    setColorIcon(iconColor)
  }, [disabled])

  return (
    <MuiButton
      className={[classes.button, className].join(' ')}
      disableElevation
      disabled={disabled}
      disableRipple
      {...props}
    >
      {React.cloneElement(children, { color: colorIcon })}
    </MuiButton>
  )
}

FloatingActionButton.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.element,
}

export default FloatingActionButton
