import React from 'react'
import { makeStyles } from 'tss-react/mui'
import PropTypes from 'prop-types'

import { rem } from 'src/common/utils/css'
import { CAROUSEL_TYPE } from 'src/common/components/carousel/constants'

const useStyles = makeStyles()((defaultTheme, props) => {
  const { transitionTime, variant, spacing } = props

  if (variant === CAROUSEL_TYPE.SECONDARY) {
    return {
      container: { overflowX: 'clip' },
      carousel: {
        overflowX: 'clip',
        whiteSpace: 'nowrap',
        display: 'inline-flex',
      },
      item: {
        whiteSpace: 'normal',
        transition: `transform ${transitionTime}s`,
        marginRight: spacing,
      },
    }
  }

  return {
    carousel: {
      transition: `transform ${transitionTime}s`,
      display: 'inline-flex',
    },
    item: {
      whiteSpace: 'normal',
      marginRight: spacing,
    },
  }
})

export default function Carousel(props) {
  const {
    children,
    activeIndex,
    spacing,
    transitionTime,
    movableLeft,
    movableRight,
    variant,
    columns,
  } = props
  if (!children || typeof children === 'string') return null

  const { classes } = useStyles({ transitionTime, variant, spacing })
  const [slideSpaces, setSlideSpaces] = React.useState([])
  const [space, setSpace] = React.useState(0)
  const [containerWidth, setContainerWidth] = React.useState(0)
  const [screenWidth, setScreenWidth] = React.useState(0)

  const ref = React.useRef([])
  const refContainer = React.useRef()

  React.useEffect(() => {
    setScreenWidth(window.innerWidth)
    window.addEventListener(
      'resize',
      () => setScreenWidth(window.innerWidth),
      false
    )
  }, [typeof window !== 'undefined' ? window.innerWidth : undefined])

  React.useEffect(() => {
    const getSpace = () => {
      if (!slideSpaces) {
        movableLeft(false)
        movableRight(false)
        return 0
      }

      if (variant === CAROUSEL_TYPE.SECONDARY) {
        const lastSpace = slideSpaces[slideSpaces.length - 1]

        if (lastSpace <= containerWidth) {
          movableLeft(false)
          movableRight(false)
          return 0
        }

        if (activeIndex <= 0) {
          movableLeft(false)
          movableRight(true)
          return 0
        }

        const activeSpace = slideSpaces[activeIndex - 1]

        if (lastSpace - activeSpace <= containerWidth) {
          movableLeft(true)
          movableRight(false)
          return lastSpace - containerWidth
        }

        movableLeft(true)
        movableRight(true)

        return slideSpaces[activeIndex - 1]
      }

      const lastSpace = slideSpaces[slideSpaces.length - 1]

      if (lastSpace <= containerWidth) {
        movableLeft(false)
        movableRight(false)
        return 0
      }

      if (activeIndex <= 0) {
        movableLeft(false)
        movableRight(true)
        return 0
      }

      const activeSpace = slideSpaces[activeIndex - 1]

      if (lastSpace - activeSpace <= containerWidth) {
        movableLeft(true)
        movableRight(false)
        return lastSpace - containerWidth
      }

      movableLeft(true)
      movableRight(true)

      return slideSpaces[activeIndex - 1]
    }

    setSpace(getSpace())
  }, [activeIndex, slideSpaces])

  React.useEffect(() => {
    const list = ref?.current?.children
    const arr = []
    let last = 0

    setContainerWidth(refContainer?.current?.offsetWidth)

    if (list) {
      if (variant === CAROUSEL_TYPE.SECONDARY) {
        for (let i = 0; i < list.length; i++) {
          const current = (containerWidth - (columns - 1) * spacing) / columns
          arr.push(last + current + spacing)
          last += current + spacing
        }
        arr[arr.length - 1] -= spacing
        setSlideSpaces(arr)
        return
      }

      for (let i = 0; i < list.length; i++) {
        const current = list[i].offsetWidth
        arr.push(last + current + spacing)
        last += current + spacing
      }

      arr[arr.length - 1] -= spacing
    }

    setSlideSpaces(arr)
  }, [children, screenWidth, variant])

  if (variant === CAROUSEL_TYPE.SECONDARY) {
    return (
      <div ref={refContainer} className={classes.container}>
        <div ref={ref} className={classes.carousel}>
          {React.Children.map(children, (child, index) =>
            React.cloneElement(child, {
              className: classes.item,
              style: {
                width: (containerWidth - (columns - 1) * spacing) / columns,
                transform: `translateX(-${rem(space)})`,
              },
            })
          )}
        </div>
      </div>
    )
  }

  return (
    <div ref={refContainer}>
      <div
        ref={ref}
        className={classes.carousel}
        style={{ transform: `translateX(-${rem(space)})` }}
      >
        {React.Children.map(children, (child, index) =>
          React.cloneElement(child, {
            className: classes.item,
          })
        )}
      </div>
    </div>
  )
}

Carousel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  activeIndex: PropTypes.number,
  spacing: PropTypes.number,
  transitionTime: PropTypes.number,
  movableLeft: PropTypes.func,
  movableRight: PropTypes.func,
  variant: PropTypes.oneOf([CAROUSEL_TYPE.PRIMARY, CAROUSEL_TYPE.SECONDARY]),
  columns: PropTypes.number,
}

Carousel.defaultProps = {
  children: undefined,
  activeIndex: 0,
  spacing: 0,
  transitionTime: 1,
  movableLeft: () => {},
  movableRight: () => {},
  variant: CAROUSEL_TYPE.PRIMARY,
  columns: 4,
}
